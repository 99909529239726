.Auth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px;
  padding: 5px;
}

.ButtonAuth {
  float: left;
}

.Checkbox {
  width: 300px;
  font-size: 16px;
}

.nav-link-auth {
  font-weight: bold;
  font-size: 22px;
  text-decoration: none;
  margin: 10px;
  padding: 0 10px 0 10px;
}

a {
  color: darkblue;
}

a:hover {
  color: #363f54;
}

a:active {
  color: #363f54;
}