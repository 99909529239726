.Home{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px;
  padding: 5px;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
  text-align: left;
}

.News {
  border-radius: 1px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,.14);
  margin: 10px;
  padding: 10px;
}

.container {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.container img {
  width: 100%;
  height: auto;
}

.Home p {
  text-align: left;
}

.Home ol {
  text-align: left;
}

.discord {
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
}

.nav-link-small {
  display: contents;
  font-size: inherit;
}

.small-text {
  font-size: 12px;
  color: #f01f30;
  font-style: italic;
}

