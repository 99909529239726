.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

html {
  overflow: visible;
  overflow-y: scroll;
}
