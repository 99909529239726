.Body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  text-align: center;
  width: 90%;
  margin: 15px;
  border-radius: 2px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,.14);
  transition: border, box-shadow .3s;
  min-height: 100px;
}