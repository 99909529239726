.Video {
  display: block;
  margin: 5px;
  padding: 5px;
}

.container {
  width: 100%;
  height: 100%;
  margin: 0px;
}

