.Navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,.14);
  transition: border, box-shadow .3s;
  letter-spacing: normal;
}

.nav-link {
  display: inline;
  font-weight: bold;
  font-size: 22px;
  text-decoration: none;
  margin: 5px;
  padding: 0 4px 0 4px;
}

a {
  color: darkblue;
}

a:hover{
  color: #363f54;
}

a:active{
  color: #363f54;
}