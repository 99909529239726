.Account{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 5px;
  padding: 5px;
}

.form-control {
  width: 300px;
  border-radius: 2px;
  border: 1px solid #ccc;
  margin: 5px;
  padding: 10px;
}

.form-control:hover{
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .1);
}

.Account td {
  text-align: left;
}

.button {
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #ccc;
  color: #000;
  margin: 10px;
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

.button:hover {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .1);
}

.button:focus {
  outline: none;
}

.button:active {
  box-shadow: inset 2px 2px 1px rgba(0, 0, 0, .3);
}

.button:disabled {
  background: #ccc;
  color: #000;
  cursor: not-allowed;
}