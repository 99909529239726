.PrivacyPolicy{
  display: block;
  justify-content: center;
  margin: 10px;
  padding: 10px;
}

.container {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.container img {
  width: 100%;
  height: auto;
}

.PrivacyPolicy p {
  text-align: left;
  margin: 10px;
}

.PrivacyPolicy h3{
  text-align: left;
}

.Table {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
}

